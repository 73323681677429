import { DEFAULT_SCOREBOARD_STATE } from "../../lib/state/DefaultState";
export class LocalStorage {
    static getDefaultCredentials() {
        return JSON.parse(localStorage.getItem("__scoreboard_user") || JSON.stringify({ username: "", password: "" }));
    }
}
Object.defineProperty(LocalStorage, "getScoreboard", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: () => {
        return JSON.parse(localStorage.getItem("__scoreboard_state") || JSON.stringify(DEFAULT_SCOREBOARD_STATE));
    }
});
Object.defineProperty(LocalStorage, "setScoreboard", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (scoreboard) => {
        localStorage.setItem("__scoreboard_state", JSON.stringify(scoreboard));
    }
});
Object.defineProperty(LocalStorage, "setDefaultCredentials", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (username, password) => {
        localStorage.setItem("__scoreboard_user", JSON.stringify({ username, password }));
    }
});
