var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import "../reset.css";
import { initializeApp } from "firebase/app";
import { getDatabase, onValue, ref, set } from "firebase/database";
import { browserLocalPersistence, getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { FirebaseError } from "@firebase/util";
import { DEFAULT_SCOREBOARD_STATE } from "../lib/state/DefaultState";
import { sleep } from "../lib/helper/sleep";
import { LocalStorage } from "./storage/LocalStorage";
import { ScoreboardContainer } from "./scoreboard-container";
import { LoginContainer } from "./login-container";
import { CONFIG } from "../config";
const firebaseConfig = FIREBASE_CONFIG;
document.title = `${document.title} | Version ${PACKAGE_VERSION}`;
(() => __awaiter(void 0, void 0, void 0, function* () {
    const config = Object.assign(Object.assign({}, firebaseConfig), { persistence: browserLocalPersistence });
    initializeApp(config);
    const auth = getAuth();
    yield auth.setPersistence(browserLocalPersistence);
    let user = getAuth().currentUser;
    let scoreboardRef;
    const scoreboardState = Object.assign(Object.assign({}, LocalStorage.getScoreboard()), DEFAULT_SCOREBOARD_STATE);
    function handleLogout() {
        return __awaiter(this, void 0, void 0, function* () {
            yield signOut(auth);
            user = null;
        });
    }
    function handleLogin(username, password) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const userCredential = yield signInWithEmailAndPassword(auth, username, password);
                user = userCredential.user;
                LocalStorage.setDefaultCredentials(username, password);
                if (!scoreboardRef) {
                    getFirebaseData(user);
                    return undefined;
                }
                return undefined;
            }
            catch (error) {
                if (error instanceof FirebaseError) {
                    if (error.code === "auth/invalid-email") {
                        return new Error("Invalid E-Mail");
                    }
                    if (error.code === "auth/missing-password") {
                        return new Error("Missing Password.");
                    }
                    if (error.code === "auth/user-not-found") {
                        return new Error("User was not found.");
                    }
                    if (error.code === "auth/wrong-password") {
                        return new Error("Incorrect Password");
                    }
                    if (error.code === "auth/too-many-requests") {
                        return new Error("Too many login attempts.");
                    }
                }
                throw error;
            }
        });
    }
    const scoreboardContainer = new ScoreboardContainer(() => __awaiter(void 0, void 0, void 0, function* () {
        yield Promise.all([handleLogout(), sleep(300)]);
        document.body.append(loginContainer);
        document.body.removeChild(scoreboardContainer);
    }), scoreboardState, (scoreboardState) => __awaiter(void 0, void 0, void 0, function* () {
        const db = getDatabase();
        yield set(ref(db, `${CONFIG.FirebaseDatabaseName}/${user === null || user === void 0 ? void 0 : user.uid}`), scoreboardState);
    }));
    function getFirebaseData(user) {
        const db = getDatabase();
        scoreboardRef = ref(db, `${CONFIG.FirebaseDatabaseName}/${user.uid}`);
        onValue(scoreboardRef, (snapshot) => __awaiter(this, void 0, void 0, function* () {
            const data = snapshot.val();
            scoreboardContainer.setScoreboardState(Object.assign(Object.assign({}, scoreboardState), data));
        }), { onlyOnce: true });
    }
    const loginContainer = new LoginContainer((username, password) => __awaiter(void 0, void 0, void 0, function* () {
        const [error] = yield Promise.all([handleLogin(username, password), sleep(300)]);
        if (!error) {
            document.body.append(scoreboardContainer);
            document.body.removeChild(loginContainer);
        }
        return error;
    }), LocalStorage.getDefaultCredentials());
    if (user) {
        getFirebaseData(user);
        document.body.append(scoreboardContainer);
    }
    else {
        document.body.append(loginContainer);
    }
}))();
