var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import "./login-container.css";
import { setComponent } from "../lib/framework";
import { LoginComponent } from "./login-component";
export class LoginContainer extends HTMLElement {
    constructor(handleLogin, defaultCredentials) {
        super();
        this.innerHTML = this.render();
        this.style.marginInline = "24px";
        const loginComponent = new LoginComponent(defaultCredentials, (username, password) => __awaiter(this, void 0, void 0, function* () {
            return yield handleLogin(username, password);
        }));
        this.querySelector("#login-form-container").append(loginComponent);
        const downloadCxtButton = this.querySelector("#download-cxt-button");
        downloadCxtButton.addEventListener("click", () => {
            window.open("./hard-bulls-baseball-ticker-cxt.zip");
        });
    }
    render() {
        return `
            <div style="margin-inline: 24px;">
              <div id="login-form-container"></div>
              <div>
                <button id="download-cxt-button">Download Chrome Extension</button>
              </div>
            </div>
        `;
    }
}
setComponent("login-container", LoginContainer);
