var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { setComponent } from "../lib/framework";
import "./login-component.css";
export class LoginComponent extends HTMLElement {
    constructor(defaultCredentials, onSubmit) {
        super();
        Object.defineProperty(this, "onSubmit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: onSubmit
        });
        Object.defineProperty(this, "usernameInput", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "passwordInput", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "loginButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "loginErrorDiv", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.style.height = "100%";
        this.innerHTML = this.render();
        this.usernameInput = this.querySelector("#login-username");
        this.passwordInput = this.querySelector("#login-password");
        this.loginButton = this.querySelector("#login-button");
        this.loginErrorDiv = this.querySelector("#login-error");
        this.usernameInput.value = defaultCredentials.username || "";
        this.passwordInput.value = defaultCredentials.password || "";
        const form = this.querySelector("#login-form");
        form.addEventListener("submit", (event) => __awaiter(this, void 0, void 0, function* () {
            event.preventDefault();
            yield this.submit();
        }));
    }
    submit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.loginButton.disabled = true;
            this.passwordInput.disabled = true;
            this.usernameInput.disabled = true;
            const error = yield this.onSubmit(this.usernameInput.value, this.passwordInput.value);
            if (error) {
                this.usernameInput.classList.add("has-error");
                this.passwordInput.classList.add("has-error");
                this.loginErrorDiv.textContent = error.message;
            }
            this.passwordInput.disabled = false;
            this.usernameInput.disabled = false;
            this.loginButton.disabled = false;
        });
    }
    render() {
        return `
            <form id="login-form">
              <div>
                  <label for="login-username">Username</label>
                <input autocomplete="username" required type="email" id="login-username"/>
              </div>
              <div>
                  <label for="login-password">Password</label>
                <input autocomplete="current-password" required type="password" id="login-password"/>
              </div>
              <div>
                <button id="login-button">Login</button>
              </div>
              <div id="login-error"></div>
            </form>
        `;
    }
}
setComponent("login-component", LoginComponent);
