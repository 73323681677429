import { InningHalfEnum } from "../model/InningHalfEnum";
import { TeamsRepository } from "../api/TeamsRepository";
import font_EurostileBold from "../assets/fonts/EurostileBold.woff2";
export const DEFAULT_SCOREBOARD_STATE = {
    bases: [],
    inning: {
        value: 1,
        half: InningHalfEnum.TOP,
    },
    score: [0, 0],
    outs: 0,
    strikes: 0,
    balls: 0,
    homeBatterName: "",
    awayBatterName: "",
    homePitcherName: "",
    awayPitcherName: "",
    batterAvg: 0,
    pitcherEra: 0,
};
export const DEFAULT_PLAYERS_STATE = {
    hidePlayers: false,
    hideStatistics: false,
    homePlayers: [],
    awayPlayers: [],
};
export const DEFAULT_POPUP_STATE = {
    followTicker: false,
    enableRemote: false,
};
const defaultHomeTeam = TeamsRepository.findById("hb_1");
const defaultAwayTeam = TeamsRepository.findById("hb_2");
export const DEFAULT_TEAMS_STATE = {
    home: "Away",
    away: "Home",
    homeLogo: defaultHomeTeam === null || defaultHomeTeam === void 0 ? void 0 : defaultHomeTeam.logo,
    awayLogo: defaultAwayTeam === null || defaultAwayTeam === void 0 ? void 0 : defaultAwayTeam.logo,
    homeGradient: defaultHomeTeam.gradient,
    awayGradient: defaultAwayTeam.gradient,
    homeLogoShadow: defaultHomeTeam.logoShadow,
    awayLogoShadow: defaultAwayTeam.logoShadow,
};
export const DEFAULT_STYLE_STATE = {
    overlayFilterColor: "#00ff00",
    background1: {
        startColor: "#404040",
        endColor: "#232323",
        endPercentage: 30,
        startPercentage: 70,
        angle: 180,
    },
    background2: {
        startColor: "#e0e0e0",
        endColor: "#c4c4c4",
        startPercentage: 55,
        endPercentage: 70,
        angle: 180,
    },
    fontColor1: "#ffffff",
    fontColor2: "#000000",
    activeInningColor: "#c21414",
    inactiveInningColor: "#a4a4a4",
    activeBaseColor: "#d3c43d",
    minimumPlayerNameWidth: 18,
    inactiveBaseColor: "#c2c2c2",
    hideCounts: false,
    hideBases: false,
    font: {
        id: "eurostile",
        name: "Eurostile",
        data: font_EurostileBold,
    },
    fontLineHeight: 1.05,
    borderSize: 2,
    borderColor: "#000000",
};
export const DEFAULT_SPONSORS_STATE = {
    sponsorsTitle: "Hard Bulls are sponsored by",
    sponsorsInterval: 5000,
    sponsors: [],
};
export const DEFAULT_OPTIONS_STATE = {
    leagueLogoShadow: "#888888",
    league: undefined,
    style: DEFAULT_STYLE_STATE,
    tickerInterval: 1000, // 1 second
    refreshInterval: 60000, // 1 minute
    remote: {
        firebaseConfig: undefined,
        username: undefined,
        password: undefined,
    },
};
