import "../reset.css";
import "../shared.css";
import "./index.css";
import { InningHalfEnum } from "../lib/model/InningHalfEnum";
import { BaseEnum } from "../lib/model/BasesEnum";
import { BaseButton } from "./base-button";
import { setComponent } from "../lib/framework";
export class ScoreboardController extends HTMLElement {
    constructor(scoreboardState, handleUpdate) {
        super();
        Object.defineProperty(this, "scoreboardState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: scoreboardState
        });
        Object.defineProperty(this, "handleUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: handleUpdate
        });
        Object.defineProperty(this, "homeAddButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "homeMinusButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "awayAddButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "awayMinusButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "firstBaseButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "thirdBaseButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "secondBaseButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "strikeButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "outButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "ballButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "resetBasesButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "inningAddButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "inningMinusButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "resetCountButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "handleBaseClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (button) => {
                const active = !this.scoreboardState.bases.includes(button.base);
                this.updateBase(button.base, active);
                this.sync();
                button.setActive(active);
                this.resetBasesButton.disabled = this.scoreboardState.bases.length === 0;
            }
        });
        Object.defineProperty(this, "updateScoreValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (key, value) => {
                this.scoreboardState = Object.assign(Object.assign({}, this.scoreboardState), { [key]: value });
                this.updateValueLabels();
            }
        });
        Object.defineProperty(this, "resetCounts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.scoreboardState = Object.assign(Object.assign({}, this.scoreboardState), { balls: 0, strikes: 0 });
                this.updateValueLabels();
                this.sync();
                this.resetCountButton.disabled = this.scoreboardState.balls === 0 && this.scoreboardState.strikes === 0;
            }
        });
        Object.defineProperty(this, "updateInning", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                let inningHalf = this.scoreboardState.inning.half;
                let inningValue = this.scoreboardState.inning.value;
                if (value < 0) {
                    if (inningHalf === InningHalfEnum.TOP) {
                        inningHalf = InningHalfEnum.BOTTOM;
                        inningValue = Math.max(1, this.scoreboardState.inning.value - 1);
                    }
                    else if (inningHalf === InningHalfEnum.BOTTOM) {
                        inningHalf = InningHalfEnum.TOP;
                        inningValue = Math.max(1, this.scoreboardState.inning.value);
                    }
                }
                else {
                    if (inningHalf === InningHalfEnum.TOP) {
                        inningHalf = InningHalfEnum.BOTTOM;
                        inningValue = Math.max(1, this.scoreboardState.inning.value);
                    }
                    else if (inningHalf === InningHalfEnum.BOTTOM) {
                        inningHalf = InningHalfEnum.TOP;
                        inningValue = Math.max(1, this.scoreboardState.inning.value + 1);
                    }
                }
                this.updateScoreValue("inning", {
                    half: inningHalf,
                    value: inningValue,
                });
            }
        });
        this.innerHTML = this.render();
        this.homeAddButton = this.querySelector("#home-add-button");
        this.homeMinusButton = this.querySelector("#home-minus-button");
        this.awayAddButton = this.querySelector("#away-add-button");
        this.awayMinusButton = this.querySelector("#away-minus-button");
        this.firstBaseButton = new BaseButton(BaseEnum.FIRST, this.scoreboardState.bases.includes(BaseEnum.FIRST), () => this.handleBaseClick(this.firstBaseButton));
        this.secondBaseButton = new BaseButton(BaseEnum.SECOND, this.scoreboardState.bases.includes(BaseEnum.SECOND), () => this.handleBaseClick(this.secondBaseButton));
        this.thirdBaseButton = new BaseButton(BaseEnum.THIRD, this.scoreboardState.bases.includes(BaseEnum.THIRD), () => this.handleBaseClick(this.thirdBaseButton));
        const baseContainer = this.querySelector("#base-buttons");
        baseContainer.append(this.thirdBaseButton, this.secondBaseButton, this.firstBaseButton);
        this.strikeButton = this.querySelector("#strike-button");
        this.ballButton = this.querySelector("#ball-button");
        this.outButton = this.querySelector("#out-button");
        this.inningAddButton = this.querySelector("#inning-add-button");
        this.inningMinusButton = this.querySelector("#inning-minus-button");
        this.resetBasesButton = this.querySelector("#reset-bases-button");
        this.resetCountButton = this.querySelector("#reset-count-button");
        this.homeAddButton.addEventListener("click", () => {
            const value = this.scoreboardState.score[0] + 1;
            this.updateScoreValue("score", [value, this.scoreboardState.score[1]]);
            this.sync();
            this.homeMinusButton.disabled = value === 0;
        });
        this.homeMinusButton.addEventListener("click", () => {
            const value = Math.max(0, this.scoreboardState.score[0] - 1);
            this.updateScoreValue("score", [value, this.scoreboardState.score[1]]);
            this.sync();
            this.homeMinusButton.disabled = value === 0;
        });
        this.awayAddButton.addEventListener("click", () => {
            const value = this.scoreboardState.score[1] + 1;
            this.updateScoreValue("score", [this.scoreboardState.score[0], value]);
            this.sync();
            this.awayMinusButton.disabled = value === 0;
        });
        this.awayMinusButton.addEventListener("click", () => {
            const value = Math.max(0, this.scoreboardState.score[1] - 1);
            this.updateScoreValue("score", [this.scoreboardState.score[0], value]);
            this.sync();
            this.awayMinusButton.disabled = value === 0;
        });
        this.strikeButton.addEventListener("click", () => {
            const value = this.scoreboardState.strikes === 2 ? 0 : this.scoreboardState.strikes + 1;
            this.updateScoreValue("strikes", value);
            this.sync();
            this.resetCountButton.disabled = this.scoreboardState.balls === 0 && value === 0;
        });
        this.ballButton.addEventListener("click", () => {
            const value = this.scoreboardState.balls === 3 ? 0 : this.scoreboardState.balls + 1;
            this.updateScoreValue("balls", value);
            this.sync();
            this.resetCountButton.disabled = value === 0 && this.scoreboardState.strikes === 0;
        });
        this.outButton.addEventListener("click", () => {
            if (this.scoreboardState.outs === 2) {
                this.updateScoreValue("outs", 0);
                this.sync();
                return;
            }
            this.updateScoreValue("outs", this.scoreboardState.outs + 1);
            this.sync();
        });
        this.resetBasesButton.addEventListener("click", () => {
            this.clearBases();
            this.sync();
        });
        this.resetCountButton.addEventListener("click", () => {
            this.resetCounts();
            this.sync();
        });
        this.inningAddButton.addEventListener("click", () => {
            this.updateInning(1);
            this.clearBases();
            this.resetCounts();
            this.updateScoreValue("outs", 0);
            this.sync();
            this.inningMinusButton.disabled = false;
        });
        this.inningMinusButton.addEventListener("click", () => {
            this.updateInning(-1);
            this.sync();
            this.inningMinusButton.disabled =
                this.scoreboardState.inning.value === 1 && this.scoreboardState.inning.half === InningHalfEnum.TOP;
        });
        this.setState(scoreboardState);
    }
    sync() {
        this.handleUpdate(this.scoreboardState);
    }
    setState(scoreboardState) {
        this.scoreboardState = scoreboardState;
        this.updateValueLabels();
        this.resetBasesButton.disabled = this.scoreboardState.bases.length === 0;
        this.resetCountButton.disabled = this.scoreboardState.balls === 0 && this.scoreboardState.strikes === 0;
        this.homeMinusButton.disabled = this.scoreboardState.score[0] === 0;
        this.awayMinusButton.disabled = this.scoreboardState.score[1] === 0;
        this.inningMinusButton.disabled =
            this.scoreboardState.inning.value === 1 && this.scoreboardState.inning.half === InningHalfEnum.TOP;
        if (this.scoreboardState.bases.includes(BaseEnum.FIRST)) {
            this.firstBaseButton.setActive(true);
        }
        if (this.scoreboardState.bases.includes(BaseEnum.SECOND)) {
            this.secondBaseButton.setActive(true);
        }
        if (this.scoreboardState.bases.includes(BaseEnum.THIRD)) {
            this.thirdBaseButton.setActive(true);
        }
    }
    updateValueLabels() {
        this.homeAddButton.querySelector(".value").textContent = this.scoreboardState.score[0].toString();
        this.awayAddButton.querySelector(".value").textContent = this.scoreboardState.score[1].toString();
        this.inningAddButton.querySelector(".value").textContent = `${this.scoreboardState.inning.half === InningHalfEnum.TOP ? "T" : "B"}${this.scoreboardState.inning.value}`;
        this.strikeButton.querySelector(".value").textContent = this.scoreboardState.strikes.toString();
        this.ballButton.querySelector(".value").textContent = this.scoreboardState.balls.toString();
        this.outButton.querySelector(".value").textContent = this.scoreboardState.outs.toString();
    }
    updateBase(base, active) {
        if (active) {
            this.updateScoreValue("bases", [...this.scoreboardState.bases, base]);
            return;
        }
        this.updateScoreValue("bases", this.scoreboardState.bases.filter((v) => v !== base));
    }
    clearBases() {
        this.updateScoreValue("bases", []);
        this.firstBaseButton.setActive(false);
        this.secondBaseButton.setActive(false);
        this.thirdBaseButton.setActive(false);
        this.resetBasesButton.disabled = this.scoreboardState.bases.length === 0;
    }
    render() {
        return `
    <div class="controller-scoreboard">
      <div class="controller-row">
        <button id="home-add-button">Home +<span class="value"></span></button>
        <button id="home-minus-button">Home -</button>
        <button id="away-add-button">Away +<span class="value"></span></button>
        <button id="away-minus-button">Away -</button>
      </div>
      <div class="controller-row" id="base-buttons">
      </div>
      <div class="controller-row">
        <button id="strike-button">Strike<span class="value"></span></button>
        <button id="ball-button">Ball<span class="value"></span></button>
        <button id="out-button">Out<span class="value"></span></button>
      </div>
      <div class="controller-row">
        <button id="inning-add-button">Inning +<span class="value"></span></button>
        <button id="inning-minus-button">Inning -</button>
        <button id="reset-bases-button">Reset Bases</button>
        <button id="reset-count-button">Reset Count</button>
      </div>
    </div>
    `;
    }
}
setComponent("scoreboard-controller", ScoreboardController);
