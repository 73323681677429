import { setComponent } from "../lib/framework";
import { LogoutComponent } from "./logout-component";
import { ScoreboardController } from "./scoreboard-controller";
export class ScoreboardContainer extends HTMLElement {
    constructor(handleLogout, scoreboardState, handleScoreboardUpdate) {
        super();
        Object.defineProperty(this, "scoreboardController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        const logoutComponent = new LogoutComponent(() => {
            handleLogout();
        });
        logoutComponent.style.height = "10%";
        this.scoreboardController = new ScoreboardController(scoreboardState, (state) => handleScoreboardUpdate(state));
        this.scoreboardController.style.height = "90%";
        this.style.height = "100%";
        this.style.display = "flex";
        this.style.flexDirection = "column";
        this.style.justifyContent = "stretch";
        this.append(this.scoreboardController);
        this.append(logoutComponent);
    }
    setScoreboardState(scoreboardState) {
        this.scoreboardController.setState(scoreboardState);
    }
}
setComponent("scoreboard-container", ScoreboardContainer);
