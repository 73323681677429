import { setComponent } from "../lib/framework";
export class BaseButton extends HTMLElement {
    constructor(base, isActive = false, onClick) {
        super();
        Object.defineProperty(this, "base", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base
        });
        Object.defineProperty(this, "isActive", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: isActive
        });
        Object.defineProperty(this, "button", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.innerHTML = this.render();
        this.button = this.querySelector(`#base-${this.base}-button`);
        this.button.addEventListener("click", () => {
            onClick();
        });
        this.style.width = "100%";
        this.setActive(isActive);
    }
    toggle() {
        this.isActive = !this.isActive;
        this.button.classList.toggle("inactive-base");
        this.button.classList.toggle("active-base");
    }
    setActive(active) {
        this.isActive = active;
        if (active) {
            this.button.classList.remove("inactive-base");
            this.button.classList.add("active-base");
        }
        else {
            this.button.classList.add("inactive-base");
            this.button.classList.remove("active-base");
        }
    }
    render() {
        return `
      <button id="base-${this.base}-button">${this.base}. Base</button>
    `;
    }
}
setComponent("base-button", BaseButton);
