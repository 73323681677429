import { setComponent } from "../lib/framework";
import "./logout-component.css";
export class LogoutComponent extends HTMLElement {
    constructor(onLogout) {
        super();
        Object.defineProperty(this, "onLogout", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: onLogout
        });
        Object.defineProperty(this, "logoutButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.style.height = "100%";
        this.innerHTML = this.render();
        this.logoutButton = this.querySelector("#logout-button");
        this.logoutButton.addEventListener("click", () => {
            this.logout();
        });
    }
    logout() {
        this.logoutButton.disabled = true;
        if (window.confirm("Logout from scoreboard?")) {
            this.onLogout();
        }
        this.logoutButton.disabled = false;
    }
    render() {
        return `
          <button id="logout-button">Logout</button>
    `;
    }
}
setComponent("logout-component", LogoutComponent);
